import classes from "./Signin.module.css"
import {SignIn} from 'aws-amplify-react'
import { ISignInProps } from 'aws-amplify-react/lib-esm/Auth/SignIn'
import NWasteIcon from "../assets/img/nwaste_icon.svg"
import Loader from "react-loader-spinner"

export class CustomSignIn extends SignIn {
  constructor(props: ISignInProps) {
    super(props)
    this._validAuthStates = ['signIn', 'signedOut']
  }


  showComponent(theme: any) {
    return (
      <div className={classes.LoginContainer}>
                <div style={{textAlign: "center", marginBottom: "20px", height: "80px"}}>
                    <img src={NWasteIcon} alt={"App icon"} style={{height: "100%"}}/>
                </div>
                <form className={classes.LoginForm}>
                    <label>NOM D'UTILISATEUR</label>
                    <input type="email" id="username" key="username" name="username" onChange={this.handleInputChange} required/>

                    <label>MOT DE PASSE</label>
                    <input type="password" id="password" key="password" name="password" onChange={this.handleInputChange} required/>

                    {
                        this.state.loading === true ? 
                        <div className={classes.LoginSpinnerContainer}>
                            <div className={classes.LoginSpinnerContent}>
                                <Loader
                                    type="Oval"
                                    color="#FFFFFF"
                                    height={30}
                                    width={100}
                                />

                            </div>
                        </div>
                        :
                        <input type="submit" className={classes.LoginValidateButton} onClick={(event) => super.signIn(event)} value="CONNEXION"/>
                    }

                    

                    <p className={classes.LoginForgottenPasswordContainer}> Mot de passe oublié ?{" "}<label onClick={() => this.props.onStateChange !== undefined ? this.props.onStateChange("forgotPassword") : undefined}>Réinitialiser le mot de passe</label></p>
                
                    <label style={{textAlign: "center"}}>L'accès à ce service est strictement réservé aux utilisateurs authentifiés et disposants d'un accès valide.</label>
                    <br />
                    <label style={{textAlign: "center"}}>Tous droits réservés Fastpoint {new Date(Date.now()).getFullYear()}</label>
                </form>
            </div>
    )
  }
}