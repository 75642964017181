import { Component } from 'react';

import {DataStore, Predicates, SortDirection,} from "aws-amplify"


import '@aws-amplify/ui/dist/style.css';
import { Action, Cask } from '../models';
import classes from './Futs.module.css';
import { AmplifyManager, HubListener } from '../helpers/AmplifyManager';
import AddFut from './add_fut/AddFut';
import ReactExport from "react-data-export";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faCross, faEraser, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getDataset } from '../helpers/ExcelHelper';
import Loader from 'react-loader-spinner';
import { getFormattedDatetime } from '../helpers/DateHelper';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

interface IProps {}
interface IState {
    casks: Cask[],
    casksSubscription: undefined|{unsubscribe: Function}
    actionsSubscription: undefined|{unsubscribe: Function},
    lastUpdate: string|undefined,
    addCaskVisible: boolean,
    editingCaskId?: string,
    loading: boolean
}

class Casks extends Component<IProps, IState> implements HubListener {
    
    constructor(props: IProps){
        super(props)
        this.state = {
            casks: [],
            casksSubscription: undefined,
            actionsSubscription: undefined,
            lastUpdate: undefined,
            addCaskVisible: false,
            editingCaskId: undefined,
            loading: true
        }
    }

    async componentDidMount(){
        AmplifyManager.getInstance().subscribe(this)
        if(AmplifyManager.getInstance().isReady()){
            await this.setState({loading: false})
            this.refreshCasks()
            this.refreshLastAction()
        }
        
        this.setupListener()
    }
    
    componentWillUnmount(){
        AmplifyManager.getInstance().unsubscribe(this)
        if(this.state.casksSubscription !== undefined){
            (this.state.casksSubscription).unsubscribe()
        }

        if(this.state.actionsSubscription !== undefined){
            (this.state.actionsSubscription).unsubscribe()
        }
    }
    
    onReady = async() => {
        await this.setState({loading: false})
        this.refreshCasks()
        this.refreshLastAction()
    }

    setupListener(){
        
        const caskSubscription = DataStore.observe(Cask).subscribe(msg => {
            console.log(msg)
            if(msg.opType === "INSERT"){
                let casksCopy: Cask[] = [...this.state.casks]
                const newCasks = [msg.element].concat(casksCopy)
                this.setState({casks: newCasks})
            }else if(msg.opType === "UPDATE"){
                const updatedObject: Cask = msg.element
                let casksCopy: Cask[] = [...this.state.casks]
                const elementToUpdateIndex = casksCopy.findIndex((it: Cask) => it.id === updatedObject.id)
                if(elementToUpdateIndex !== -1){
                    const newObject = Cask.copyOf(casksCopy[elementToUpdateIndex], updated => {

                        if ("numLot" in updatedObject){ updated.numLot = updatedObject.numLot}
                        if ("dateCollect" in updatedObject){ updated.dateCollect = updatedObject.dateCollect}
                        if ("commentCollect" in updatedObject){ updated.commentCollect = updatedObject.commentCollect }
                        if ("dateRecept" in updatedObject){ updated.dateRecept = updatedObject.dateRecept}
                        if ("commentRecept" in updatedObject){ updated.commentRecept = updatedObject.commentRecept }
                        if ("dateRecon" in updatedObject){ updated.dateRecon = updatedObject.dateRecon }
                        if ("commentRecon" in updatedObject){ updated.commentRecon = updatedObject.commentRecon }
                        if ("customField1" in updatedObject){ updated.customField1 = updatedObject.customField1 }
                        if ("customField2" in updatedObject){ updated.customField2 = updatedObject.customField2 }
                        if ("customField3" in updatedObject){ updated.customField3 = updatedObject.customField3 }

                        //@ts-ignore
                        updated._lastChangedAt = updatedObject._lastChangedAt
                        //@ts-ignore
                        updated._version = updatedObject._version
                    })
                    
                    casksCopy[elementToUpdateIndex] = newObject
                    this.setState({casks: casksCopy})
                }
            }
        })

        const actionsSubscription = DataStore.observe(Action).subscribe(msg => {
            if(msg.opType === "INSERT" || msg.opType === "UPDATE"){
                if("dateAction" in msg.element){
                    this.setState({lastUpdate: msg.element.dateAction})
                }
                else if ("_lastChangedAt" in msg.element){
                    //@ts-ignore
                    const lastChangedAt = new Date(msg.element._lastChangedAt).toISOString()
                    this.setState({lastUpdate: lastChangedAt})
                }
            }
        })
        
        this.setState({casksSubscription: caskSubscription, actionsSubscription: actionsSubscription})
    }
    

    private refreshLastAction = async() => {
        const response = await DataStore.query(Action, Predicates.ALL, {
            sort: action => action.dateAction(SortDirection.DESCENDING),
            limit: 1
          }
        )
        this.setState({lastUpdate: response[0].dateAction})

    }

    refreshCasks = async() => {
        try {
            const response = await DataStore.query(Cask , Predicates.ALL, {
                limit: undefined
              })
            this.setState({casks: response.sort((xItem, yItem) => {
                return xItem.barcode.localeCompare(yItem.barcode)
            })})
        }catch(error){
            console.log(error)
        }
    }
    
    randomIntFromInterval = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    
    
    clearDatabase = () => {
        indexedDB.deleteDatabase("amplify-datastore")
    }

    onResetCaskClick = (cask: Cask) => {
        if (window.confirm("Etes-vous sûre de vouloir réinitialiser les informations de collecte, de réception et de recondionnement de ce fût ? Cette action ne supprime pas le fût.")) {
            AmplifyManager.getInstance().resetCaskInformations(cask)
            AmplifyManager.getInstance().createResetAction(cask)
        }
    }
    
    render(){
        let now = new Date(Date.now())
        let lot0Count = this.state.casks !== undefined ? this.state.casks.filter(it => it.numLot === 0).length : 0
        const filename = `export_${now.getFullYear()}-${(now.getMonth() + 1) < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1}-${(now.getDate()) < 10 ? `0${now.getDate()}` : now.getDate()}__${(now.getHours()) < 10 ? `0${now.getHours()}` : now.getHours()}H${(now.getMinutes()) < 10 ? `0${now.getMinutes()}` : now.getMinutes()}`
        return (  
            <>
            {
                this.state.addCaskVisible === true ? 
                    <AddFut onCreationSuccess={() => this.setState({addCaskVisible: false})} onDismissModal={() => this.setState({addCaskVisible: false})}/>
                    : null
            } 
            

            <div style={{overflow: "auto"}} className={classes.CaskRootContainer}>

                <label>Liste des fûts {(this.state.casks !== undefined && this.state.casks.length > 0) ? `(${this.state.casks.length - lot0Count} fûts)` : null}</label>
                <div className={classes.CaskActionsButtonsContainer}>
                    <ExcelFile filename={filename} element={<button disabled={this.state.loading === true} style={{backgroundColor: "rgb(3 140 206)", color: "white"}}>Exporter les données</button>}>
                        {[2, 3, 4].map(numLot => {
                           return <ExcelSheet key={'excel_sheet ' + numLot} name={"Lot " + numLot} dataSet={getDataset(numLot, this.state.casks)} />
                        })}
                    </ExcelFile>
                    <button onClick={() => this.setState({addCaskVisible: true})} style={{backgroundColor: "rgb(132 187 0)", color: "white"}}>+ Ajouter un fût</button>
                </div>
                <label className={classes.CaskContainerSubtitle}>Dernier événement : {this.state.lastUpdate ? getFormattedDatetime(this.state.lastUpdate) : null}</label>
                <div className={classes.CaskContainerTitleSeparator}/>
                <div className={classes.CaskContainerGroupContent}>
                
                <div className={classes.CasksContainer}>
                    {
                        this.state.loading === true ? <div style={{marginTop: "15px", paddingTop: "12px", paddingBottom: "12px"}}>
                        <Loader type={"Oval"} width={35} height={35} color={"#0095ff"}/>
                    </div>
                    :
                        <table>
                            <thead>
                                <tr>
                                    <th>Numéro de fût</th>
                                    <th>Numéro de lot</th>
                                    <th>Date de prise en charge pour collecte</th>
                                    <th colSpan={2}>Commentaire de prise en charge pour collecte</th>
                                    <th>Date de réception sur site 1</th>
                                    <th colSpan={2}>Commentaire de réception sur site 1</th>
                                    <th>Date de prise en charge pour reconditionnement</th>
                                    <th colSpan={2}>Commentaire de prise en charge pour reconditionnement</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.casks !== undefined ? this.state.casks.map((cask: Cask) => {
                                    if(cask.numLot === 0){
                                        return null
                                    }
                                    //@ts-ignore
                                    let ellapsedTimeSec = (new Date(Date.now()).getTime() - new Date(cask._lastChangedAt).getTime()) / 1000
                                    return (
                                        <tr key={cask.id} style={ellapsedTimeSec < (60 * 60) ? {backgroundColor: "rgba(86, 255, 0, 0.08)"} : (ellapsedTimeSec < (60 * 60 * 8) ? {backgroundColor: "rgba(84, 168, 255, 0.08)"} : undefined)}>
                                            <td className={classes.MonoText}>{cask.barcode}</td>
                                            <td className={classes.MonoText}>{cask.numLot !== 0 ? cask.numLot : "Hors contrat"}</td>

                                            <td className={classes.MonoText}>{cask.dateCollect ? getFormattedDatetime(cask.dateCollect) : null}</td>
                                            <td className={classes.CaskSpannedColumn1}>{cask.customField1 === true ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : cask.customField1 === false ? <FontAwesomeIcon icon={faExclamationTriangle} color={"orange"}/> : null}</td>
                                            <td className={classes.CaskSpannedColumn2}>{cask.commentCollect}</td>

                                            <td className={classes.MonoText}>{cask.dateRecept ? getFormattedDatetime(cask.dateRecept) : null}</td>
                                            <td className={classes.CaskSpannedColumn1}>{cask.customField2 === true ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : cask.customField2 === false ? <FontAwesomeIcon icon={faExclamationTriangle} color={"orange"}/> : null}</td>
                                            <td className={classes.CaskSpannedColumn2}>{cask.commentRecept}</td>

                                            <td className={classes.MonoText}>{cask.dateRecon ?  getFormattedDatetime(cask.dateRecon) : null}</td>
                                            <td className={classes.CaskSpannedColumn1}>{cask.customField3 === true ? <FontAwesomeIcon icon={faCheckCircle} color={"green"}/> : cask.customField3 === false ? <FontAwesomeIcon icon={faExclamationTriangle} color={"orange"}/> : null}</td>
                                            <td className={classes.CaskSpannedColumn2}>{cask.commentRecon}</td>
                                        
                                            <td>
                                                <button title="Réinitialiser le fût" className={classes.CaskActionButton} onClick={() => this.onResetCaskClick(cask)}><FontAwesomeIcon size="lg" icon={faEraser}/></button>
                                            </td>
                                        </tr>
                                        
                                        )
                                    }) : null
                                }    
                                </tbody>
                            </table>
                        }
                    </div>
                
                </div>
            </div>
        </>
        );
    }
}

export default Casks;
        