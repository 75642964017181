import React, {Component} from "react"
import classes from "./Menu.module.css"
import classesMenuItem from "./MenuItem.module.css"
import {Link} from "react-router-dom";

import NWasteLogoText from "../../assets/img/nwaste_icon.svg"

import {withRouter} from "react-router-dom"

class Menu extends Component<any, any> {

    state = {
        dashboardsMenuExpanded: true,
        adminMenuExpanded: false,
    };


    render(){
        return (

            <div className={classes.MenuContainer}>


                <img className={classes.HeaderLogo} alt={"N-Waste"} src={NWasteLogoText}/>

                <div className={classes.MenuHeaderSeparator}/>

                <>
                    <div className={[classesMenuItem.SubmenuItemContainer, "/futs" === window.location.pathname ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                        <Link to={"/futs"}>Fûts</Link>
                    </div>
                    <div className={[classesMenuItem.SubmenuItemContainer, window.location.pathname.startsWith("/actions") ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                        <Link
                            to={"/actions"}>Evénements</Link>
                    </div>
                </>

                
                <label className={classes.MenuAppVersion}>
                    <span>Version {process.env.REACT_APP_VERSION}</span>
                    <br />
                    <br />
                    <span className={classes.MenuCopyright}>Tous droits réservés Fastpoint 2021</span>
                </label>
                
            </div>
        )
    }
}

export default withRouter(Menu);
