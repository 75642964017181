import classes from "../signin/Signin.module.css"
import {ForgotPassword} from 'aws-amplify-react'
import NWasteIcon from "../assets/img/nwaste_icon.svg"

export class CustomForgotPassword extends ForgotPassword {
  constructor(props: any) {
    super(props) 
    this._validAuthStates = ['forgotPassword']
  }

  showComponent(theme: any) {
      console.log(this.state.delivery)
    return (
      <div className={classes.LoginContainer}>
                
        <div style={{textAlign: "center", marginBottom: "20px", height: "80px"}}>
            <img src={NWasteIcon} alt={"App icon"} style={{height: "100%"}}/>
        </div>
        <form className={classes.LoginForm}>

                {this.state.delivery === null ? 
                <>
                    <label className={classes.ForgotPasswordLabel}>Réinitialisez votre mot de passe</label>
                    
                    <br />

                    <label>Nom d'utilisateur</label>
                    <input type="text" id="username" key="username" name="username" placeholder="Saisissez votre nom d'utilisateur" onChange={this.handleInputChange} required/>

                    <button className={classes.LoginValidateButton} onClick={(event) => {
                        event.preventDefault()
                        this.send()
                    }}>M'ENVOYER UN CODE</button>
                </>
                :
                <>
                    <label className={classes.ForgotPasswordLabel}>Réinitialisez votre mot de passe</label>
                    <br />
                    
                    <input value={this.state.username} type="hidden" id="username" key="username" name="username" placeholder="Saisissez votre nom d'utilisateur" onChange={this.handleInputChange} required/>
                    
                    <label>Code reçu par {this.state.delivery.DeliveryMedium} ({this.state.delivery.Destination})</label>
                    <input type="text" id="code" key="code" name="code" placeholder="Saisissez le code reçu" onChange={this.handleInputChange} required/>
                    <input type="password" id="password" key="password" name="password" placeholder="Nouveau mot de passe" onChange={this.handleInputChange} required/>
                    <button className={classes.LoginValidateButton} onClick={(event) => {
                        event.preventDefault()
                        this.submit()
                    }}>RÉINITIALISER LE MOT DE PASSE</button>
                </>
                }

                    <p className={classes.LoginForgottenPasswordContainer}>{" "}<label onClick={() => this.props.onStateChange !== undefined ? this.props.onStateChange("signIn", true) : undefined}>Retour à la connexion</label></p>
                    <label style={{textAlign: "center"}}>Tous droits réservés Fastpoint {new Date(Date.now()).getFullYear()}</label>
                </form>
            </div>
    )
  }
}